import * as VueRouter from 'vue-router';
import loginRouter from './login';

// Create VueRouter Object
const router = VueRouter.createRouter({
    mode: 'history',
    history: VueRouter.createWebHistory(),
    routes:  [
        ...loginRouter,
        {
            path: '/dashboard',
            name: 'dashboard',
            component: () => import('../views/dashboard/dashboard.vue'),
            children: [
                {
                    path: '/HelloWorld',
                    name: 'HelloWorld',
                    component: () => import('@/components/HelloWorld.vue')
                },
                {
                    path: '/dashboard/Mysite',
                    name: 'Mysite',
                    component: () => import('@/views/mysite/mysite.vue')
                },
                {
                    path: '/dashboard/UserManagement',
                    name: 'UserManagement',
                    component: () => import('@/views/userManagement/userManagement.vue')
                },
                {
                    path: '/dashboard/ChangePassword',
                    name: 'ChangePassword',
                    component: () => import('@/views/changePassword/changePassword.vue')
                },
                {
                    path: '/dashboard/OpportunitiesManagement',
                    name: 'OpportunitiesManagement',
                    component: () => import('@/views/OpportunitiesManagement/OpportunitiesManagement.vue')
                },
                {
                    path: '/dashboard/HotelDetail/:id',
                    name: 'HotelDetail',
                    component: () => import('@/views/hotelDetail/hotelDetail.vue')
                },
                {
                    path: '/dashboard/HotelDetail/:hotelID/opportunity/:id',
                    name: 'HotelOpportunity',
                    component: () => import('@/views/hotelOpportunity/hotelOpportunity.vue')
                },
                {
                    path: '/dashboard/HotelDetail/:hotelID/opportunity',
                    name: 'HotelOpportunity',
                    component: () => import('@/views/hotelOpportunity/hotelOpportunity.vue')
                },
                {
                    path: '/dashboard/HotelDetail/:hotelID/energy',
                    name: 'Energy',
                    component: () => import('@/views/energy/energy.vue')
                },
                {
                    path: '/dashboard/HotelDetail/:hotelID/auditexport/:year/:auditID',
                    name: 'AuditExport',
                    component: () => import('@/views/energy/energy.vue')
                },
                {
                    path: '/dashboard/Dictionary',
                    name: 'Dictionary',
                    component: () => import('@/views/dictionary/dictionary.vue')
                }
            ]
        },
        {
            path: '/',
            redirect: '/dashboard/Mysite'
        },
        {
          path: '/404',
          name: 'page404',
          component: () => import('../views/error/404')
        },
        {path: '/:pathMatch(.*)*', redirect: '/404'}
      ]
});

import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

router.beforeEach((to) => {
    // If user is not heading to Login Page, then check if it has token
    if(to.path != '/login' && to.path != '/404'){
        if(cookies.get('token') == null){
            return { name: 'LoginPage' }
        }
        
    }
});

export default router;