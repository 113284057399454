// Create App
import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App);

//globalProperties
app.config.globalProperties.$filters = {
    // Format moeny grouping
    moneyGrouping(value){
        // let val = (value/1).toFixed(2).replace('.', ',')
        let newValue = String(value).replaceAll(/,/g, '');
        return String(newValue).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
}

// Router
import router from './router'

// Store
import store from './store';
app.use(store);

// UI
    //Element-UI
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
    //Text Editor with EQuillEditor
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
app.component('QuillEditor', QuillEditor);

// Cookies
import VueCookies from 'vue3-cookies'
app.use(VueCookies);

// Mobile Detection
import VueMobileDetection from "vue-mobile-detection";
app.use(VueMobileDetection);

app.use(router);
app.use(ElementPlus);

// Mount Vue.js to DOM
app.mount('#app')