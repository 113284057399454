import request from '@/api/utils/request';

export function userLogin(data){
    return request({
        url: "/api/v1/user/login/",
        method: 'POST',
        data
    });
}

export function userLogout(userID){
    const data = {"userID": userID}
    return request({
        url: "/api/v1/user/logout/",
        method: 'POST',
        data
    });
}