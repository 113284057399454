import {getToken, setToken, removeToken, setUserID, removeUserID, getUserID} from '@/utils/auth';
import {userLogin, userLogout} from '@/api/login/login';
import { getUserInfo } from '@/api/userManagement/userManagement';

const state = {
  token: getToken(),
  userID: getUserID(),
  username: '',
  userRole: '',
  lastLoginTime: ''
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_USER_ID: (state, userID) => {
    state.userID = userID;
  },
  SET_USERNAME: (state, username) => {
    state.username = username;
  },
  SET_USER_ROLE(state, userRole) {
    state.userRole = userRole;
  },
  SET_LAST_LOGIN_TIME(state, lastLoginTime) {
    state.lastLoginTime = lastLoginTime;
  }
};

const actions = {
  // 登入
  login({commit}, data) {
    return new Promise((resolve, reject) => {
      userLogin(data).then(res => {
        if(res.data.resultCode == 200){
          commit('SET_TOKEN', res.data.token);
          commit('SET_USER_ID', res.data.userID);
          commit('SET_USERNAME', res.data.userName);
          commit('SET_USER_ROLE', res.data.userRole);
          if(data.rememberMe){
            setToken(res.data.token, "30D");
            setUserID(res.data.userID, "30D");
          }else{
            setToken(res.data.token, "0");
            setUserID(res.data.userID, "0");
          }
        }
        resolve(res);
        }).catch(error => {
          reject(error);
        });
    });
  },

  // 登出
  logout({commit}) {
    return new Promise((resolve, reject) => {
      userLogout(getUserID()).then(() => {
        commit('SET_TOKEN', '');
        commit('SET_USER_ID', '');
        commit('SET_USERNAME', '');
        commit('SET_USER_ROLE', '');
        commit('SET_LAST_LOGIN_TIME', '');
        removeToken();
        removeUserID();
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },

  // Get User Info
  getUserInfo({commit}) {
    return new Promise((resolve, reject) => {
      getUserInfo(getUserID()).then(res => {
        if(res.data.resultCode == 215){
          commit('SET_USERNAME', res.data.result.userName);
          commit('SET_USER_ROLE', res.data.result.userRole);
          const temp = res.data.result.lastLogin.replace("T", " ") + " (UTC+0)";
          commit('SET_LAST_LOGIN_TIME', temp);
        }
        resolve(res);
      }).catch(error => {
        reject(error);
      });
    });
  }

};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};