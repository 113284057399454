import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export function getToken(TokenKey = 'token') {
  return cookies.get(TokenKey);
}

export function setToken(token, expire = 0, TokenKey = 'token') {
  return cookies.set(TokenKey, token, expire);
}

export function removeToken(TokenKey = 'token') {
  return cookies.remove(TokenKey);
}

export function getUserID() {
  return cookies.get('userID');
}

export function setUserID(userId, expire) {
  return cookies.set('userID', userId, expire);
}

export function removeUserID() {
  return cookies.remove('userID');
}
