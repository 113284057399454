<template>
    <div class="container">
        <el-row class="body">
            <el-col class="background" :sm="12" :xs="0">
                <el-image :src="require('@/assets/img/aemBG.webp')" class="loginBG"></el-image>
            </el-col>
            <el-col :sm="12" :xs="24" class="loginContainer">
                <div class="subLoginContainer">
                    <el-image :src="require('@/assets/img/logo.webp')" class="logo"></el-image>
                    <div class="login">

                        <el-form label-position="top" label-width="100px" class="loginForm" ref="loginForm"
                            :model="loginFormData" :rules="rules">
                            <el-form-item label="Username" prop="username">
                                <el-input placeholder="Username" v-model="loginFormData.username"
                                    @keyup.enter="loginSubmit('loginForm')" />
                            </el-form-item>
                            <el-form-item label="Password" prop="password">
                                <el-input type="password" placeholder="Password" show-password
                                    v-model="loginFormData.password" @keyup.enter="loginSubmit('loginForm')" />
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="17">
                                    <el-checkbox label="Remember me" v-model="loginFormData.remebemberMe" />
                                </el-col>
                                <el-col :span="7">
                                    <a @click="forgetPasswordBtn"
                                        style="text-decoration: underline; cursor: pointer;">Forget Password</a>
                                </el-col>
                            </el-form-item>
                            <el-form-item v-if="formErrorMsg">
                                <a class="errorMessage">{{ formErrorMsg }}</a>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="warning" class="loginBtn"
                                    @click="loginSubmit('loginForm')">Login</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </el-col>
        </el-row>
        <el-row class="footer">
            <a href="https://aem.hk/en/contact-us/" style="text-decoration: none; color: black; padding-left: 5px;">Contact
                us</a>
            <span>Copyright © 2023 aemami.com.hk. All Rights Reserved.</span>
        </el-row>
    </div>
</template>
  
<script>
import { ElMessage, ElMessageBox } from 'element-plus'

export default {
    name: 'LoginPage',

    data() {
        return {
            formErrorMsg: '',
            loginFormData: {
                username: '',
                password: '',
                remebemberMe: false
            },
            rules: {
                username: [
                    { required: true, message: 'Please enter your username', trigger: 'change' }
                ],
                password: [
                    { required: true, message: 'Please enter your password', trigger: 'change' }
                ]
            }
        }
    },
    created: function () {
    },
    methods: {
        loginSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const data = {
                        'username': this.loginFormData.username,
                        'password': this.loginFormData.password,
                        'rememberMe': this.loginFormData.remebemberMe
                    }
                    this.$store.dispatch('user/login', data).then(res => {
                        if (res.data.resultCode != 200) {
                            this.formErrorMsg = res.data.message;
                        } else {
                            this.$router.push("/dashboard/Mysite");
                        }
                    });
                } else {
                    ElMessage.error('Please enter username and password.');
                }
            });
        },
        forgetPasswordBtn() {
            ElMessageBox.alert('<p>Please contact AEM admin to reset your password<p><uo><li> +852 2333 5138</li><li>info@activenergy.com.hk</li></ol>', 'Notice', {
                dangerouslyUseHTMLString: true,
            })
        }
    }
}
</script>
<style scoped>
@media screen and (max-width: 767px) {
    .subLoginContainer {
        max-width: 500px;
        background-color: rgba(255, 255, 255, 0.98);
        border-radius: 15px;
    }

    .body {
        background-image: url("../../assets/img/aemBG.webp");
        background-position: center;
    }

    .footer {
        font-size: small;
        background-color: lightcyan;
        height: 5vh;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.subLoginContainer {
    max-width: 500px
}

.loginForm {
    max-width: 460px
}

.logo {
    max-width: 500px
}

.loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95vh;
}

.loginBG {
    max-height: -webkit-fill-available;
    height: 95vh;
    width: 100%;
}

.container {
    height: 100vh;
    max-height: -webkit-fill-available;
}

.backgound .body {
    height: 95vh;

}

.footer {
    background-color: lightcyan;
    height: 5vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logindiv {
    width: 35vw;
    justify-content: center;
}

.login {
    margin: 0 20px 0 20px;
}

.loginBtn {
    width: 100%;
}

.errorMessage {
    color: red;
    font-size: medium;
}</style>
